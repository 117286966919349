import * as ACTION from "../constants/leadflowActionTypes";
import React from "react";

const initialState = {
    leadFlows: null,
    zapier: '',
};

const marketingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.STORE_LEADFLOW:
            return {...state, leadFlows: action.payload.html, zapier: action.payload.zapier}

        default:
            return state;
    }
}
export default marketingReducer;
