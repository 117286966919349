import { all, takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import * as LEADFLOW_API from '../api/leadflowApi';
import * as ACTION from '../constants/leadflowActionTypes'
import Utils from "../helpers/Utils";

function* leadFlowWatcher() {
    yield takeEvery(ACTION.FETCH_USER, fetchUserFlow);
    yield takeEvery(ACTION.FETCH_LEADFLOW, fetchLeadFlowMiddleware);
    yield takeEvery(ACTION.FETCH_LEADFLOW_SETTINGS, fetchLeadFlowDataMiddleware);
}

function* fetchUserFlow(action) {
    try {
        const response = yield call(LEADFLOW_API.fetchUser, action.payload);
        let responseData = response.data;
        yield put({type: ACTION.STORE_USER, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchLeadFlowMiddleware(action) {
    try {
        const response = yield call(LEADFLOW_API.fetchLeadFlowList, action.payload);
        let responseData = response.data;
        yield put({type: ACTION.STORE_LEADFLOW, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchLeadFlowDataMiddleware(action) {
    try {
        const response = yield call(LEADFLOW_API.fetchLeadFlowSetting, action.payload);
        let responseData = response.data;
        yield put({type: ACTION.STORE_LEADFLOW_SETTINGS, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

export default function* leadflowMiddleware() {
    yield all([
        leadFlowWatcher(),
    ])
}
