import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center'
    },
}));

const Loading = props => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress size={props.size ?? 20} />
        </div>
    );
}

export default Loading;