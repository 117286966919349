import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const fetchUser = async (payload) => {
    return sendRequest("POST", Api.fetchUser, payload);
}

export const fetchLeadFlowList = async (payload) => {
    return sendRequest("POST", Api.leadFlow, payload);
}

export const createLeadFlow = async (payload) => {
    return sendRequest("POST", Api.createLeadFlow, payload);
}

export const updateLeadFlow = async (payload) => {
    return sendRequest("POST", Api.updateLeadFlow, payload);
}

export const deleteLeadFlow = async (payload) => {
    return sendRequest("POST", Api.deleteLeadFlow, payload);
}

export const fetchLeadFlowSetting = async (payload) => {
    return sendRequest("POST", Api.fetchLeadFlowSetting, payload);
}

export const saveLeadFlow = async (payload) => {
    return sendRequest("POST", Api.saveLeadFlow, payload);
}

export const fetchCalendars = async (payload) => {
    return sendRequest("POST", Api.fetchCalendars, payload);
}

export const assignCalendarToLeadFlow = async (payload) => {
    return sendRequest("POST", Api.assignCalendar, payload);
}

export const fetchContactSources = async (payload) => {
    return sendRequest("POST", Api.fetchContactSources, payload);
}

export const addContactSource = async (payload) => {
    return sendRequest("POST", Api.addContactSource, payload);
}