import './lead_flow.css';
import { fetchLeadFlow } from '../../actions/leadflowAction'
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import React, {useContext, useEffect, useState} from "react";
import {connect} from "react-redux";
import If, {Else} from "if-else-react";
import Loading from "../Common/Loading";
import Helper from "../../helpers/Helper";
import CoreConstants from "../../constants/CoreConstants";
import AddNewLeadFlow from "./AddNewLeadFlow";
import EditLeadFlow from "./EditLeadFlow";
import {deleteLeadFlow} from "../../api/leadflowApi";
import Utils from "../../helpers/Utils";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import AssignCalendarModal from "./AssignCalendarModal";
import AssignContactSourceModal from "./AssignContactSourceModal";
import {Link, useHistory} from "react-router-dom";
import {HistoryProvider} from "../../App";
import DynamicSkeleton from '../Common/DynamicSkeleton';

const LeadFlow = props => {
    const history = useHistory();
    const backHistory = useContext(HistoryProvider);
    const [leadFlows, setLeadFlows] = useState(props.leadFlows);
    const [loading, setLoading] = useState(true);
    const [addNewModal, setAddNewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [updateLeadFlow, setUpdateLeadFlow] = useState(null);
    const [selectedLeadFlow, setSelectedLeadFlow] = useState(0);
    const [openCalendarModal, setOpenCalendarModal] = useState(false);
    const [openContactSourceModal, setOpenContactSourceModal] = useState(false);

    const goToLink = (link) => {
        backHistory.push({
            pathname: link
        });
    }

    useEffect(() => {
        if (props.leadFlows == null) {
            props.fetchLeadFlow();
        } else {
            setLoading(false);
            setLeadFlows(props.leadFlows)
        }
    }, [props.leadFlows]);

    const getFullName = contact =>{
        if(!contact.first_name && !contact.last_name){
            if(contact.number)
                return contact.number;
            else
                return contact.email;
        }
        else{
            return contact.first_name+" "+contact.last_name;
        }
    };

    const selectProspectSource = (e, leadFlow) => {
        e.preventDefault();

        setSelectedLeadFlow(leadFlow);
        setOpenContactSourceModal(true);
    }

    const showCalendarModal = (e, leadFlow) => {
        e.preventDefault();

        setSelectedLeadFlow(leadFlow);
        setOpenCalendarModal(true);
    }

    const editLeadFlow = (id, title) => {
        setOpenEditModal(true);
        setUpdateLeadFlow({
            id: id,
            title: title
        });
    }

    const leadFlowDelete = (id) => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'This delete action can\'t be reverted',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteLeadFlow({id: id}).then(response => {
                            if (response.data.status === 'success') {
                                props.fetchLeadFlow();
                                Utils.showNotification(response.data.html, 'success');
                            } else {
                                Utils.showNotification(response.data.html, 'error');
                            }
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

    const contactSourceImageNotFound = e => {
        e.target.parentNode.children[1].style.display = 'block';
    }

    const renderLeadFlows = () => {
        let leadFlowList = [];
        if (leadFlows != null) {
            leadFlows.map((data, index) => {
                leadFlowList.push(
                    <div className="lead_flow_list" key={index}>
                        <div className="list__title">
                            Rules for <a className="calendar" href="#"><strong>{Helper.sourceTypeName(data.source_type)}</strong></a> <br/> {(data.title.length > 35 ? data.title.substr(0, 35) + ' ...' : data.title)}
                            <If condition={data.source_type == 4}>
                                <span>{data.email}</span>
                            </If>
                            {data.contact != null &&
                                <span>
                                    •Last Lead <a href={/contacts/ + data.contact.id}>{getFullName(data.contact)}</a>
                                    <span>{data.contact.created_at.for_human}</span>
                                </span>
                            }
                        </div>

                        <If condition={data.source_type == CoreConstants.SOURCE_TYPE.API}>
                            <div className="list__subtitle">
                                Use this token to connect leadflow with API <br/> <b>{data.token}</b>
                            </div>
                            <div className="btn__wrapper alt">

                            </div>
                        </If>

                        <If condition={data.source_type == CoreConstants.SOURCE_TYPE.ZAPIER}>
                            <div className="list__subtitle">
                                <p>Connect with zapier to import contacts</p>
                            </div>
                            <div className="btn__wrapper alt">
                                { props.zapier ?
                                    <a target="_blank" href={props.zapier} className="zapier-btn accent--bg--color">
                                        <span>Connect with Zapier</span>
                                    </a>
                                    :
                                    <span className="text-danger">Zapier connection button not found</span>
                                }
                            </div>
                        </If>

                        <If condition={data.source_type == CoreConstants.SOURCE_TYPE.FACEBOOK}>
                            <div className="list__subtitle">
                                {data.fb_connect == null &&
                                    <p>Connect with your facebook page to import contacts</p>
                                }
                                {data.fb_connect != null &&
                                    <p>Connected with <b>{data.fb_connect.fb_page ? data.fb_connect.fb_page.page_name : ''}</b></p>
                                }
                            </div>
                            <div className="btn__wrapper alt">
                                <a href={'/integrations/facebook'} className="zapier-btn accent--bg--color">
                                    {data.fb_connect == null &&
                                        <span>Connect with facebook</span>
                                    }
                                    {data.fb_connect != null &&
                                        <span>Change facebook connection</span>
                                    }
                                </a>
                            </div>
                        </If>

                        <If condition={data.source_type == CoreConstants.SOURCE_TYPE.PARSER}>
                            <div className="list__subtitle">
                                {data.leadflow_contact_source == null ?
                                    <p>Configure where this campaign should import its contacts from.</p>
                                    :
                                    <div className="single_contact_source">
                                        <img className="contact_source_img" src={`${Utils.getAccountData('baseUrl')}/parser/${data.leadflow_contact_source.contact_source.logo}`} alt={`${data.leadflow_contact_source.contact_source.name} Image`} onError={(e) => contactSourceImageNotFound(e)}/>
                                        <p>{data.leadflow_contact_source.contact_source.name}</p>
                                    </div>
                                }
                            </div>
                            <div className="btn__wrapper alt">
                                <a href="#" onClick={(e) => selectProspectSource(e, data)} className="zapier-btn accent--bg--color">
                                    {data.leadflow_contact_source == null &&
                                        <span>Connect prospect source</span>
                                    }
                                    {data.leadflow_contact_source != null &&
                                        <span>Update prospect source</span>
                                    }
                                </a>
                            </div>
                        </If>

                        <If condition={data.source_type == CoreConstants.SOURCE_TYPE.CALENDAR}>
                            <div className="list__subtitle">
                                {data.calendar.length === 0 &&
                                    <p>Not Connected with Calendar</p>
                                }
                                {data.calendar.length > 0 &&
                                    <p>Connected with <b>{data.calendar[0].title} </b>
                                        <If condition={data.calendar.length > 1}>
                                            and {data.calendar.length - 1} more.
                                        </If>
                                    </p>
                                }
                            </div>
                            <div className="btn__wrapper alt">
                                <a href="#" onClick={(e) => showCalendarModal(e, data)} className="zapier-btn accent--bg--color">
                                    <If condition={data.calendar.length === 0}>
                                        Connect Calendar
                                    </If>
                                    <If condition={data.calendar.length > 0}>
                                        Change Calendar
                                    </If>
                                </a>
                            </div>
                        </If>

                        <div className="btn__wrapper">
                            <Link to={`leadFlow/settings/${data.id}`}>
                                <span className="button_icon">
                                    <SettingsIcon />
                                </span>
                                <span className="btn_text">
                                    Settings
                                </span>
                            </Link>
                            <button onClick={() => editLeadFlow(data.id, data.title)}>
                                <span className="button_icon">
                                    <EditIcon />
                                </span>
                                <span className="btn_text">
                                    Edit
                                </span>
                            </button>
                            <button onClick={() => leadFlowDelete(data.id)}>
                                <span className="button_icon">
                                    <DeleteIcon />
                                </span>
                                <span className="btn_text">
                                    Delete
                                </span>
                            </button>
                        </div>
                    </div>
                );
            });
        }

        return leadFlowList;
    }

    return (
        <div className="lead_flow_content">
            {
                history.location.state !== undefined && history.location.state.from == 'settingPage' &&
                <div class="back_button_to_account mt-3 ml-3">
                    <a className={"accent--bg--color"} href="javascript:void(0)" onClick={()=>{goToLink('/user/settings')}} >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z" fill="white"></path></svg>
                        <span> Back</span>
                    </a>
                </div>
            }
            <div className="lead_flow_top_content lead_flow_list">
                <h1>lead flow</h1>
                <div className="create_new_leadflow_btn">
                    <a href="#" onClick={(e) => {e.preventDefault();setAddNewModal(true)}} className="modal-trigger accent--bg--color">
                        <span className="btn_icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="10" fill="white"/>
                                <path d="M13.8461 10.0001C13.8461 10.3546 13.8153 10.6411 13.4609 10.6411H10.641V13.461C10.641 13.8148 10.3544 13.8462 9.99996 13.8462C9.64548 13.8462 9.35894 13.8148 9.35894 13.461V10.6411H6.53906C6.18522 10.6411 6.15381 10.3546 6.15381 10.0001C6.15381 9.6456 6.18522 9.35906 6.53906 9.35906H9.35894V6.53919C9.35894 6.1847 9.64548 6.15393 9.99996 6.15393C10.3544 6.15393 10.641 6.1847 10.641 6.53919V9.35906H13.4609C13.8153 9.35906 13.8461 9.6456 13.8461 10.0001Z" className={"accent--fill--color"}/>
                            </svg>
                        </span>Create New Leadflow
                    </a>
                    <If condition={addNewModal}>
                        <AddNewLeadFlow addNewModal={addNewModal} setAddNewModal={setAddNewModal}/>
                    </If>
                </div>
            </div>
            <div className="lead_flow_table">
                <div className="lead_flow_lists__wrapper">
                    <If condition={loading}>
                        <DynamicSkeleton height='120px'/>
                    <Else />
                        {renderLeadFlows()}
                    </If>
                </div>
            </div>
            <If condition={openEditModal}>
                <EditLeadFlow
                    openEditModal={openEditModal}
                    setOpenEditModal={setOpenEditModal}
                    leadFlow={updateLeadFlow}
                />
            </If>
            <If condition={openCalendarModal}>
                <AssignCalendarModal
                    openCalendarModal={openCalendarModal}
                    setOpenCalendarModal={setOpenCalendarModal}
                    selectedLeadFlow={selectedLeadFlow}
                />
            </If>
            <If condition={openContactSourceModal}>
                <AssignContactSourceModal
                    openContactSourceModal={openContactSourceModal}
                    setOpenContactSourceModal={setOpenContactSourceModal}
                    selectedLeadFlow={selectedLeadFlow}
                />
            </If>
        </div>
    );
}

const mapStateToProps = state => ({
    leadFlows: state.leadFlowReducer.leadFlows,
    zapier: state.leadFlowReducer.zapier
});

const mapDispatchToProps = dispatch => ({
    fetchLeadFlow : () => dispatch(fetchLeadFlow()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadFlow);