import Fade from '@material-ui/core/Fade';
import React, {useRef, useState} from "react";
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import EditIcon from '@material-ui/icons/Edit';
import SimpleReactValidator from 'simple-react-validator';
import {fetchLeadFlow} from "../../actions/leadflowAction";
import {connect} from "react-redux";
import {updateLeadFlow} from "../../api/leadflowApi";
import Utils from '../../helpers/Utils';
import If, {Else} from 'if-else-react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const App = props => {

    const [, forceUpdate] = useState()
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: {forceUpdate: () => forceUpdate(1)},
        className: 'text-danger'
    }));
    const [title, setTitle] = useState(props.leadFlow.title);
    const [loader, setLoader] = useState(false);

    const submitUpdateLeadFlow = () => {
        if (validator.current.allValid() ) {
            if (!loader) {
                let params = {
                    id: props.leadFlow.id,
                    title: title,
                }
                setLoader(true);
                updateLeadFlow(params).then(response => {
                    setLoader(false);
                    if (response.data.status === 'success') {
                        props.fetchLeadFlow();
                        props.setOpenEditModal(false);
                        Utils.showNotification(response.data.html, 'success');
                    } else {
                        Utils.showNotification(response.data.html, 'error');
                    }
                });
            }
        } else {
            validator.current.showMessages();
        }
    }

    return (
        <React.Fragment>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                // className={classes.modal}
                open={props.openEditModal}
                onClose={props.setOpenEditModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.openEditModal}>
                    <div className="add_leadflow_modal" id="add_new_leadFlow_modal">
                        <div className="top_content d-flex justify-content-between align-items-center p-4">
                            <div className="sub_title">
                                <h2>Update LeadFlow</h2>
                            </div>
                            <div className="cross_button d-flex justify-content-center align-items-center modal-close" onClick={(e) => props.setOpenEditModal(false)}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.1252 17.0812L17.1357 15.0693L6.878 4.80485L4.86747 6.8167L15.1252 17.0812Z"
                                          fill="white"></path>
                                    <path d="M6.878 17.0838L17.1357 6.81934L15.1252 4.80749L4.86747 15.072L6.878 17.0838Z"
                                          fill="white"></path>
                                </svg>
                            </div>
                        </div>
                        <div className="input_field_group">
                            <div className="row">
                                <div className="col s12 title_input_field">
                                    <label htmlFor="first_name">Title <span>*</span></label>
                                    <input placeholder="Enter leadflow title" name="title" id="first_name" type="text" value={title} onChange={(e) => setTitle(e.target.value)}/>
                                    
                                    {validator.current.message('title', title, 'required|alpha num')}
                                </div>
                            </div>
                        </div>
                        <div className="modal__buttons d-flex justify-content-center align-content-center">
                            <If condition={loader}>
                                <button type="buton" className="add__group__btn">
                                    <MoreHorizIcon/>
                                    <span className="mx-2">Update LeadFlow</span>
                                </button>
                            <Else />
                                <button type="buton" className="add__group__btn accent--bg--color" onClick={() => submitUpdateLeadFlow()}>
                                    <EditIcon />
                                    <span className="mx-2">Update LeadFlow</span>
                                </button>
                            </If>
                            <button onClick={() => props.setOpenEditModal(false)} type='button' className="modal-close modal__close__btn">
                                <svg width="21" height="22" viewBox="0 0 21 22" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.1016 3.90156C14.2016 0.0015626 7.80156 0.0015626 3.90156 3.90156C0.00156271 7.80156 0.00156271 14.2016 3.90156 18.1016C7.80156 22.0016 14.1016 22.0016 18.0016 18.1016C21.9016 14.2016 22.0016 7.80156 18.1016 3.90156ZM13.8016 15.2016L11.0016 12.4016L8.20156 15.2016L6.80156 13.8016L9.60156 11.0016L6.80156 8.20156L8.20156 6.80156L11.0016 9.60156L13.8016 6.80156L15.2016 8.20156L12.4016 11.0016L15.2016 13.8016L13.8016 15.2016Z"
                                        fill="white"/>
                                </svg>
                                <span className="mx-2">Close</span>
                            </button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    leadFlows: state.leadFlowReducer.leadFlows
});

const mapDispatchToProps = dispatch => ({
    fetchLeadFlow : () => dispatch(fetchLeadFlow()),
});

const EditLeadFlow = connect(mapStateToProps, mapDispatchToProps)(App);

export default EditLeadFlow;