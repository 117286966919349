import {useEffect, useState} from "react";
import {connect} from "react-redux";
import If, {Else} from 'if-else-react';
import AssigneeModal from "./AssigneeModal";
import { updateAssignee, updateAssigneeCampaign } from '../../actions/leadflowAction'
import CampaignAssigneeModal from "./CampaignAssigneeModal";

const Assignee = props => {
    const [openAssigneeModal, setOpenAssigneeModal] = useState(false);
    const [openCampaignModal, setOpenCampaignModal] = useState(false);

    const assigneeUpdate = (assignType, assignTo) => {
        props.updateAssignee({ruleId: props.rule.id, assignType: assignType, assignTo: assignTo});
    }

    const updateAssigneeCampaign = (campaignId, campaignType) =>{
        props.updateAssigneeCampaign({ruleId: props.rule.id, campaignId: campaignId, campaignType: campaignType});
    };

    const getAssignee = () => {
        let assignee = null;
        if(parseInt(props.rule.assign_type) === 1) {
            let selectedUser = props.members.find((user) => {
                return (parseInt(user.id) === parseInt(props.rule.assign_to))
            });
            if(typeof selectedUser != 'undefined'){
                assignee = selectedUser.full_name;
            }
        }

        if(parseInt(props.rule.assign_type) === 2) {
            let selectedUser = props.groups.find((user) => {
                return (parseInt(user.id) === parseInt(props.rule.assign_to))
            });

            console.log(props.groups, selectedUser,props.rule.assign_to)

            if(typeof selectedUser != 'undefined'){
                assignee = selectedUser?.name ?? "--no assignee--";
            }
            else{
                assignee = "-- no assignee --"
            }
        }

        if(parseInt(props.rule.assign_type) === 3) {
            assignee = props.pool.name;
        }

        console.log("assignee", assignee)

        return assignee;
    }

    const getCampaignTitle = () => {
        let campaignTitle = "No Campaign";

        if(props.rule.campaign_id != null){
            let campaign = null;
            if(typeof props.rule.campaign_type != 'undefined'){
                if(props.rule.campaign_type === 1){
                    campaign = props.campaigns.find((data)=>{
                        if(parseInt(data.id) === parseInt(props.rule.campaign_id)){
                            return data;
                        }
                    });
                }else if(props.rule.campaign_type === 2){
                    campaign = props.templates.find((data)=>{
                        if(parseInt(data.id) === parseInt(props.rule.campaign_id)){
                            return data;
                        }
                    });
                }
            }


            if(campaign != null){
                campaignTitle = campaign.title;
            }
        }

        if(typeof props.rule.campaign_type == 'undefined'){
            let campaign = props.campaigns.find((data)=>{
                if(parseInt(data.id) === parseInt(props.rule.campaign_id)){
                    return data;
                }
            });
            if(typeof campaign != 'undefined'){
                campaignTitle = campaign.title;
            }
        }

        return campaignTitle;
    }

    return (
        <div className="assign_content d-flex align-items-center">
            <If condition={openAssigneeModal}>
                <AssigneeModal
                    callback={assigneeUpdate}
                    openAssigneeModal={openAssigneeModal}
                    setOpenAssigneeModal={setOpenAssigneeModal}
                    assign_to={props.rule.assign_to}
                    assign_type={props.rule.assign_type}
                />
            </If>
            <If condition={openCampaignModal}>
                <CampaignAssigneeModal
                    callback={updateAssigneeCampaign}
                    openCampaignModal={openCampaignModal}
                    setOpenCampaignModal={setOpenCampaignModal}
                    assign_to={props.rule.assign_to}
                    assign_type={props.rule.assign_type}
                />
            </If>
            <div className="assign_to assing_btns">
                <span className="assing_btns_text">Assigned to</span>
                <button
                    className={"accent--bg--color"} onClick={() => setOpenAssigneeModal(true)}>{ getAssignee() }</button>
            </div>
            {props.rule.assign_type != 2 &&
                <div className="assign_campaign assing_btns">
                    <span className="assing_btns_text">Assigned to</span>
                    <button className={"accent--bg--color"} onClick={() => setOpenCampaignModal(true)}>{ getCampaignTitle() }</button>
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        groups: state.leadFlowSettingReducer.groups,
        members: state.leadFlowSettingReducer.members,
        pool: state.leadFlowSettingReducer.pool,
        campaigns: state.leadFlowSettingReducer.campaigns,
        templates: state.leadFlowSettingReducer.templates,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateAssignee: (params) => dispatch(updateAssignee(params)),
        updateAssigneeCampaign: (params) => dispatch(updateAssigneeCampaign(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Assignee)