import React, { Component } from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';

// import components

import LeadFlow from "../components/LeadFlow/Index";
import Settings from "../components/LeadFlow/Settings";
import HealthCheck from '../components/HealthCheck';

class Routes extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/"><Redirect to="/leadFlow" /></Route>

                    <Route exact path="/leadFlow" component={LeadFlow}/>
                    <Route exact path="/leadFlow/settings/:leadFlowId" component={Settings}/>
                    <Route path={"/lead-flow/health-check"} component={HealthCheck}/>
                </Switch>
            </Router>
        );
    }
}

export default Routes;