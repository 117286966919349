import React from "react";
import * as ACTION from "../constants/leadflowActionTypes";
import {ERROR_CLEAR, ERROR_RULE, ERROR_RULE_CONDITION} from "../constants/leadflowActionTypes";

export const fetchLeadFlow = (payload, callback) => ({
    type: ACTION.FETCH_LEADFLOW,
    payload: {payload, callback}
});

export const fetchUserInfo = payload => ({
    type: ACTION.FETCH_USER,
    payload: payload
});

export const fetchLeadFlowData = (payload) => ({
    type: ACTION.FETCH_LEADFLOW_SETTINGS,
    payload: payload
});

export const updateAssignee = (payload) => ({
    type: ACTION.UPDATE_ASSIGNEE,
    payload: payload
});

export const updateAssigneeCampaign = (payload) => ({
    type: ACTION.UPDATE_ASSIGNEE_CAMPAIGN,
    payload: payload
});

export const conditionTypeUpdate = (payload) => ({
    type: ACTION.UPDATE_RULE_CONDITION_TYPE,
    payload: payload
});

export const deleteRule = (payload) => ({
    type: ACTION.DELETE_RULE,
    payload: payload
});

export const updateCondition = (payload) => ({
    type: ACTION.UPDATE_RULE_CONDITION,
    payload: payload
});

export const removeCondition = (payload) => ({
    type: ACTION.REMOVE_RULE_CONDITION,
    payload: payload
});

export const addNewCondition = (payload) => ({
    type: ACTION.ADD_RULE_CONDITION,
    payload: payload
});

export const addNewRule = (payload) => ({
    type: ACTION.ADD_NEW_RULE,
    payload: payload
});
export const showErrorRule = (payload) => ({
    type: ACTION.ERROR_RULE,
    payload: payload
});

export const showErrorRuleCondition = (payload) => ({
    type: ACTION.ERROR_RULE_CONDITION,
    payload: payload
});

export const clearErrorRule = () => ({
    type: ACTION.ERROR_CLEAR,
    payload: {}
});

export const updateOrder = payload => ({
    type: ACTION.UPDATE_RULE_ORDER,
    payload: payload
});