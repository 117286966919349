import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import App from './App';
import store from "./store/Store"
import * as serviceWorker from './serviceWorker';

window.renderLeadFlowModule = (containerId, history) => {
    ReactDOM.render(
        // <React.StrictMode>
        <React.Fragment>
            <Provider store={store}>
                <App history={history} />
            </Provider>
        </React.Fragment>,
        // </React.StrictMode>,
        document.getElementById(containerId)
    );
    serviceWorker.unregister();
};

window.unmountLeadFlowModule = (containerId) => {
    if (document.getElementById(containerId)) {
        ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
    }
};

if (process.env.REACT_APP_PRODUCTION_MODE !== true && document.getElementById('LeadFlowModule-root')) {
    ReactDOM.render(
        // <React.StrictMode>
        <React.Fragment>
            <Provider store={store}>
                <App />
            </Provider>
        </React.Fragment>,
        // </React.StrictMode>,
        document.getElementById('LeadFlowModule-root'));
    serviceWorker.unregister();
}