import {connect} from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { conditionTypeUpdate, deleteRule, addNewCondition } from '../../actions/leadflowAction';
import {Draggable} from "react-beautiful-dnd";
import Assignee from "./Assignee";
import EachCondition from "./EachCondition";
import {useEffect, useState} from "react";

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: 'transparent',

    // styles we need to apply on draggables
    ...draggableStyle
});

const App = props => {
    const [isDragStart, setIsDragStart] = useState(props.isDragStart);
    
    useEffect(() => {
        setIsDragStart(props.isDragStart);
    }, [props.isDragStart]);

    const updateConditionType = (e) => {
        props.conditionTypeUpdate({ruleId: props.rule.id, conditionType: e.target.value});
    }

    const ruleDelete = () => {
        props.deleteRule({ruleId: props.rule.id});
    }

    const renderConditions = () => {
        let conditions = [];

        props.rule.conditions.map((data,index)=>{
            conditions.push(<EachCondition order={index} condition={data} key={index}/>);
        });

        return conditions;
    }

    const addAnotherCondition = e => {
        e.preventDefault();

        let maxId = 0;
        props.rule.conditions.map((condition, index)=>{
            if(condition.id > maxId) maxId = condition.id;
        });

        let newCondition = {
            new : true,
            id: maxId + 1,
            field : 1,
            operator : 1,
            leadflow_rule_id : props.rule.id
        };

        props.addNewCondition({rule: props.rule, newCondition: newCondition});
    }

    return (
        <Draggable key={props.rule.id} draggableId={props.rule.id.toString()} index={props.order-1}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps} style={getItemStyle(snapshot.isDragging,provided.draggableProps.style)} className={"draggable-item w-100"} id={props.rule.id} key={props.rule.id} >
                    <div className="add_rule_content">
                        <div className="rule_top d-flex justify-content-between">
                            <div className="title" {...provided.dragHandleProps}>
                                <span className="title_icon">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.625 4.0625C5.625 3.19956 4.92544 2.5 4.0625 2.5C3.19956 2.5 2.5 3.19956 2.5 4.0625C2.5 4.92544 3.19956 5.625 4.0625 5.625C4.92544 5.625 5.625 4.92544 5.625 4.0625Z"
                                            stroke="#E5E8EF" strokeWidth="2" strokeMiterlimit="10"/>
                                        <path
                                            d="M11.5625 4.0625C11.5625 3.19956 10.8629 2.5 10 2.5C9.13706 2.5 8.4375 3.19956 8.4375 4.0625C8.4375 4.92544 9.13706 5.625 10 5.625C10.8629 5.625 11.5625 4.92544 11.5625 4.0625Z"
                                            stroke="#E5E8EF" strokeWidth="2" strokeMiterlimit="10"/>
                                        <path
                                            d="M17.5 4.0625C17.5 3.19956 16.8004 2.5 15.9375 2.5C15.0746 2.5 14.375 3.19956 14.375 4.0625C14.375 4.92544 15.0746 5.625 15.9375 5.625C16.8004 5.625 17.5 4.92544 17.5 4.0625Z"
                                            stroke="#E5E8EF" strokeWidth="2" strokeMiterlimit="10"/>
                                        <path
                                            d="M5.625 10C5.625 9.13706 4.92544 8.4375 4.0625 8.4375C3.19956 8.4375 2.5 9.13706 2.5 10C2.5 10.8629 3.19956 11.5625 4.0625 11.5625C4.92544 11.5625 5.625 10.8629 5.625 10Z"
                                            stroke="#E5E8EF" strokeWidth="2" strokeMiterlimit="10"/>
                                        <path
                                            d="M11.5625 10C11.5625 9.13706 10.8629 8.4375 10 8.4375C9.13706 8.4375 8.4375 9.13706 8.4375 10C8.4375 10.8629 9.13706 11.5625 10 11.5625C10.8629 11.5625 11.5625 10.8629 11.5625 10Z"
                                            stroke="#E5E8EF" strokeWidth="2" strokeMiterlimit="10"/>
                                        <path
                                            d="M17.5 10C17.5 9.13706 16.8004 8.4375 15.9375 8.4375C15.0746 8.4375 14.375 9.13706 14.375 10C14.375 10.8629 15.0746 11.5625 15.9375 11.5625C16.8004 11.5625 17.5 10.8629 17.5 10Z"
                                            stroke="#E5E8EF" strokeWidth="2" strokeMiterlimit="10"/>
                                        <path
                                            d="M5.625 15.5625C5.625 14.6996 4.92545 14 4.0625 14C3.19956 14 2.5 14.6996 2.5 15.5625C2.5 16.4254 3.19956 17.125 4.0625 17.125C4.92545 17.125 5.625 16.4254 5.625 15.5625Z"
                                            stroke="#E5E8EF" strokeWidth="2" strokeMiterlimit="10"/>
                                        <path
                                            d="M11.5625 15.9375C11.5625 15.0746 10.8629 14.375 10 14.375C9.13706 14.375 8.4375 15.0746 8.4375 15.9375C8.4375 16.8004 9.13706 17.5 10 17.5C10.8629 17.5 11.5625 16.8004 11.5625 15.9375Z"
                                            stroke="#E5E8EF" strokeWidth="2" strokeMiterlimit="10"/>
                                        <path
                                            d="M17.5 15.9375C17.5 15.0746 16.8004 14.375 15.9375 14.375C15.0746 14.375 14.375 15.0746 14.375 15.9375C14.375 16.8004 15.0746 17.5 15.9375 17.5C16.8004 17.5 17.5 16.8004 17.5 15.9375Z"
                                            stroke="#E5E8EF" strokeWidth="2" strokeMiterlimit="10"/>
                                    </svg>
                                </span>{props.order - 1}. Rule
                            </div>
                            <div className="delete_btn">
                                <button onClick={() => ruleDelete()}>
                                    <span className="delete_btn_icon">
                                        <svg width="13" height="16" viewBox="0 0 13 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.7497 1.99983H8.99979V1.49984C8.99979 0.671425 8.32824 -0.00012207 7.49983 -0.00012207H5.49987C4.67145 -0.00012207 3.99991 0.671425 3.99991 1.49984V1.99983H1.24997C0.559643 1.99983 0 2.55947 0 3.2498V4.24978C0 4.52593 0.223839 4.74977 0.499988 4.74977H12.4997C12.7759 4.74977 12.9997 4.52593 12.9997 4.24978V3.2498C12.9997 2.55947 12.4401 1.99983 11.7497 1.99983ZM4.99988 1.49984C4.99988 1.22422 5.22425 0.999855 5.49987 0.999855H7.49983C7.77544 0.999855 7.99981 1.22422 7.99981 1.49984V1.99983H4.99988V1.49984Z"
                                                fill="#FF264A"/>
                                            <path
                                                d="M0.948964 5.75037C0.859748 5.75037 0.788656 5.82493 0.792906 5.91405L1.2054 14.5714C1.24352 15.3726 1.90163 16.0001 2.70349 16.0001H10.2964C11.0983 16.0001 11.7564 15.3726 11.7945 14.5714L12.207 5.91405C12.2113 5.82493 12.1402 5.75037 12.051 5.75037H0.948964ZM8.49991 7.00034C8.49991 6.72409 8.72366 6.50035 8.9999 6.50035C9.27615 6.50035 9.49989 6.72409 9.49989 7.00034V13.5002C9.49989 13.7764 9.27615 14.0002 8.9999 14.0002C8.72366 14.0002 8.49991 13.7764 8.49991 13.5002V7.00034ZM5.99997 7.00034C5.99997 6.72409 6.22372 6.50035 6.49996 6.50035C6.7762 6.50035 6.99995 6.72409 6.99995 7.00034V13.5002C6.99995 13.7764 6.7762 14.0002 6.49996 14.0002C6.22372 14.0002 5.99997 13.7764 5.99997 13.5002V7.00034ZM3.50003 7.00034C3.50003 6.72409 3.72378 6.50035 4.00002 6.50035C4.27626 6.50035 4.50001 6.72409 4.50001 7.00034V13.5002C4.50001 13.7764 4.27626 14.0002 4.00002 14.0002C3.72378 14.0002 3.50003 13.7764 3.50003 13.5002V7.00034Z"
                                                fill="#FF264A"/>
                                        </svg>
                                    </span>Delete
                                </button>
                            </div>
                        </div>
                        {!isDragStart &&
                            <div>
                                <div className="add_rule_top_content d-flex justify-content-between align-items-center">
                                    <div className="list__title w-50 rule_btm_title d-flex align-items-center">
                                        Leads Who Meet
                                        <div className="drp_select pp_select">
                                            <Select
                                                labelId="personalize-select-label"
                                                value={props.rule.condition_type}
                                                onChange={updateConditionType}
                                            >
                                                <MenuItem value="1">All</MenuItem>
                                                <MenuItem value="2">Any</MenuItem>
                                            </Select>
                                        </div>
                                        Of these Conditions
                                    </div>
                                    <div className="top_lead_setting_btn d-flex">
                                        <div className="create_new_leadflow_btn add_rule_btn">
                                            <a href="#" className="modal-trigger" onClick={(e) => {
                                                addAnotherCondition(e)
                                            }}>
                                                <span className="btn_icon">
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.99992 9.00004H2.33325H8.99992Z" fill="#3C7EF3"/>
                                                        <path
                                                            d="M8.99992 15.6667V9.00004M8.99992 9.00004V2.33337M8.99992 9.00004H15.6666M8.99992 9.00004H2.33325"
                                                            stroke="#3C7EF3" strokeWidth="4" strokeLinecap="round"/>
                                                    </svg>
                                                </span>Add Another Condition
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                {renderConditions()}

                                <Assignee isDefault={false} rule={props.rule}/>
                            </div>
                        }
                    </div>
                </div>
            )}
        </Draggable>
    );
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        conditionTypeUpdate: (params) => dispatch(conditionTypeUpdate(params)),
        deleteRule: (params) => dispatch(deleteRule(params)),
        addNewCondition: (params) => dispatch(addNewCondition(params))
    }
}

const EachRule = connect(mapStateToProps, mapDispatchToProps)(App)

export default EachRule;
