import {useEffect, useState} from "react";
import SettingsHeader from "./SettingsHeader";
import {fetchLeadFlowData, addNewRule, fetchUserInfo, showErrorRule, showErrorRuleCondition, clearErrorRule, updateOrder} from '../../actions/leadflowAction';
import { saveLeadFlow } from '../../api/leadflowApi';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {connect} from "react-redux";
import Loading from "../Common/Loading";
import If, {Else} from 'if-else-react';
import CoreConstants from "../../constants/CoreConstants";
import { animateScroll as scroll} from 'react-scroll';
import DefaultRule from "./DefaultRule";
import EachRule from "./EachRule";
import Utils from '../../helpers/Utils';
import DynamicSkeleton from "../Common/DynamicSkeleton";

const App = props => {
    const [leadFlowId] = useState(props.match.params.leadFlowId);
    const [loading, setLoading] = useState(props.loading);
    const [isDragStart, setIsDragStart] = useState(false);

    useEffect(() => {
        props.fetchLeadFlowData({leadFlowId:leadFlowId});
        props.fetchUserInfo();
    }, []);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    const validateRule = rule => {
        return !(typeof rule.assign_to === 'undefined') ||
            (typeof rule.assign_type === 'undefined') ||
            (typeof rule.condition_type === 'undefined') ||
            (typeof rule.rule_type === 'undefined') ||
            (typeof rule.leadflow_id === 'undefined');
    }

    const validateCondition = condition => {
        if( (typeof condition.field === 'undefined') || (typeof condition.operator === 'undefined')){
            return false;
        }

        if(parseInt(condition.field) === 2){
            return true;
        }

        if(parseInt(condition.field) === 1 ){
            if(parseInt(condition.operator) === 7 || parseInt(condition.operator) === 8){
                return true;
            }
            if((typeof condition.value === 'undefined') || condition.value.length === 0){
                return false;
            }
        }

        return true;
    }

    const saveHandler = () => {
        let isError = false;
        console.log(typeof props.leadFlowRules);
        if(typeof props.leadFlowRules === 'object'){
            props.leadFlowRules.map((rule)=>{
                if(!validateRule(rule)){
                    props.showErrorRule({ruleId : rule.id, message : "Some field are missing in rule"});
                    isError = true;
                }

                if(rule.rule_type !== 1){
                    rule.conditions.map(((condition)=>{
                        if(!validateCondition(condition)){
                            props.showErrorRuleCondition({conditionId : condition.id, message : "Some field are missing in condition"});
                            isError = true;
                        }
                    }));
                }

            });
            if(isError){
                setTimeout(()=>{ props.clearErrorRule() },3000);
            }

            if(!isError){
                leadFlowSave();
            }
        }


    }

    const leadFlowSave = () => {
        saveLeadFlow({leadFlowId:leadFlowId, settings: props.leadFlowRules, removeRules: props.removeRules}).then(response => {
            setLoading(false);
            if (response.data.status === 'success') {
                Utils.showNotification(response.data.html, 'success');
            } else {
                Utils.showNotification(response.data.html, 'error');
            }
        });
    }

    const renderRules = (ruleType) => {
        let rules = [];

        if (ruleType == CoreConstants.RULE_TYPE.DEFAULT) {
            props.leadFlowRules.map((data, index)=>{
                if(data.rule_type === CoreConstants.RULE_TYPE.DEFAULT)
                    rules.push(<DefaultRule rule={data} order={index + 1} key={index} addAnotherRule={addAnotherRule}/>);
            });
        } else if (ruleType == CoreConstants.RULE_TYPE.USER_CREATED) {
            props.leadFlowRules.map((data, index)=>{
                if(data.rule_type === CoreConstants.RULE_TYPE.USER_CREATED)
                    rules.push(<EachRule rule={data} order={index + 1} key={index} isDragStart={isDragStart} />);
            });
        }


        return rules;
    }

    const addAnotherRule = (e) => {
        e.preventDefault();
        scroll.scrollToBottom({
            duration: 1000,
            delay: 10,
            smooth: true
        });

        let maxId = 0;
        props.leadFlowRules.map((data, index)=>{
            if(data.id > maxId) maxId = data.id;
        });

        let newRule = {
                new : true,
                id  : maxId + 1,
                assign_type : 1,
                priority : maxId + 1,
                rule_type : 2,
                assign_to   : props.userInfo.id,
                conditions : [{
                    leadflow_rule_id : maxId + 1,
                    new: true,
                    id : 1,
                    field : 1,
                    value : '',
                    operator : 1
                }],
                leadflow_id : leadFlowId,
                condition_type : 1

            }
        props.addNewRule({newRule: newRule});
    }

    const onDragEnd = result => {
        setIsDragStart(false);

        if(result.destination == null){
            return;
        }
        let rules = props.leadFlowRules;

        let dragIndex = 0;

        let rule = rules.find((rule,index)=>{
            if(rule.id == result.draggableId){
                dragIndex = index;
                return true;
            }
        });
        let dropIndex = result.destination.index;

        if(typeof rules[dropIndex] === 'undefined'){
            rules[dragIndex].priority = rule.priority - .10236;
        } else {
            rules[dragIndex].priority = rules[dropIndex].priority - .10236;
        }

        let keysSorted = Object.keys(rules).sort((a,b) => {
            return rules[a].priority - rules[b].priority
        });


        let sortedArray = [];

        keysSorted.map((data,index)=>{
            sortedArray[index] = rules[data];
        })

        setLoading(true);
        props.updateOrder({leadFlowRules: sortedArray});
        leadFlowSave();
    };

    const onDragStart = result => {
        setIsDragStart(true);
    };
    
    return (
        <If condition={!loading}>
                <div className="lead_flow_setting">
                    <If condition={props.errorFound}>
                        <div className="row">
                            <div className="col">
                                <div className="alert alert-danger" role="alert">
                                    <div className="alert-text">
                                        {props.errors}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </If>
                    <SettingsHeader saveHandler={saveHandler} />

                    {renderRules(CoreConstants.RULE_TYPE.DEFAULT)}

                    <DragDropContext  onDragEnd={onDragEnd} onDragStart={onDragStart}>
                        <Droppable droppableId="droppable-1" key={leadFlowId}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={{ backgroundColor: snapshot.isDraggingOver ? 'grey' : 'transparent', width: '100%' }}
                                    {...provided.droppableProps}
                                >
                                    {provided.placeholder}

                                    {renderRules(CoreConstants.RULE_TYPE.USER_CREATED)}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <div className="top_lead_setting_btn d-flex">
                        <div className="create_new_leadflow_btn add_rule_btn">
                            <a href="#" className="modal-trigger" onClick={(e) => addAnotherRule(e)}>
                                <span className="btn_icon">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.99992 9.00004H2.33325H8.99992Z" fill="#3C7EF3"></path>
                                        <path
                                            d="M8.99992 15.6667V9.00004M8.99992 9.00004V2.33337M8.99992 9.00004H15.6666M8.99992 9.00004H2.33325"
                                            stroke="#3C7EF3" stroke-width="4" stroke-linecap="round"></path>
                                    </svg>
                                </span>
                                Add Another Rule
                            </a>
                        </div>
                    </div>
                </div>
            <Else />
              <DynamicSkeleton height='120px'/>            
            </If>
    );
}

const mapStateToProps = state => {
    return {
        leadFlowData: state.leadFlowSettingReducer.leadFlowData,
        loading: state.leadFlowSettingReducer.loading,
        leadFlowRules: state.leadFlowSettingReducer.leadFlowRules,
        removeRules: state.leadFlowSettingReducer.removeRules,
        userInfo: state.leadFlowSettingReducer.userInfo,
        errors : state.leadFlowSettingReducer.errors,
        errorFound : state.leadFlowSettingReducer.errorFound,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchUserInfo: (params) => dispatch(fetchUserInfo(params)),
        fetchLeadFlowData: (params) => dispatch(fetchLeadFlowData(params)),
        addNewRule: (params) => dispatch(addNewRule(params)),
        showErrorRule: (params) => dispatch(showErrorRule(params)),
        showErrorRuleCondition: (params) => dispatch(showErrorRuleCondition(params)),
        clearErrorRule: () => dispatch(clearErrorRule()),
        updateOrder : (params) => dispatch(updateOrder(params))
    }
}

const Settings = connect(mapStateToProps, mapDispatchToProps)(App)

export default Settings;