import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import Helper from "../../helpers/Helper";
import CoreConstants from "../../constants/CoreConstants";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import If, {Else} from 'if-else-react';
import AssignCalendarModal from "./AssignCalendarModal";
import ApiDetailsModal from "./ApiDetailsModal";

const App = props => {
    const [leadFlow, setLeadFlow] = useState(props.leadFlow);
    const [openCalendarModal, setOpenCalendarModal] = useState(false);
    const [openApiModal, setOpenApiModal] = useState(false);

    useEffect(() => {
        setLeadFlow(props.leadFlow);
    }, [props.leadFlow]);

    const renderCalendars = () => {
        let calenders = [];

        if(props.calendars.length > 0){
            calenders = props.calendars.map((item, index) => {
                return (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                )
            });
        }

        return calenders;
    }

    const renderCalendarList = () => {
        let calendarList = [];

        if(leadFlow && leadFlow.calendar){
            calendarList = leadFlow.calendar.map((item, index) => {
                return (
                    <a href={window.location.origin+"/user/calendar/edit/"+item.id} target={"_blank"} className="badge badge-info mr-1 p-2" key={index}>{item.title}</a>
                )
            });
        }

        return calendarList;
    }

    const setLeadFlowCalendar = (e) => {
        alert(e.target.value);
    }

    return (
        <div className="lead_flow_top_content lead_flow_list">
            <div className="list__title">
                Rules for <a className="calendar" href="#" onClick={(e) => e.preventDefault()}><strong>{Helper.sourceTypeName(leadFlow.source_type)}</strong></a> {(leadFlow.title.length > 35 ? leadFlow.title.substr(0, 35) + ' ...' : leadFlow.title)} <br/>
                {leadFlow.user &&
                    <a className="calendar" href="#"> {leadFlow.user.full_name} </a>
                }
                <span>Rules are processed from top to bottom,drag and drop to re-order.</span>
            </div>
            <div className="top_lead_setting_btn d-flex">
                <div className="create_new_leadflow_btn lead_flow_setting_btn accent--bg--color">
                    <Link to="/leadFlow">
                        <span className="btn_icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1.875 10C1.875 14.4871 5.51289 18.125 10 18.125C14.4871 18.125 18.125 14.4871 18.125 10C18.125 5.51289 14.4871 1.875 10 1.875C5.51289 1.875 1.875 5.51289 1.875 10ZM10.625 6.87148L8.14141 9.375H13.9844V10.625H8.14141L10.625 13.1285L9.73828 14.009L5.76016 10L9.73828 5.99102L10.625 6.87148Z"
                                    fill="white"/>
                            </svg>
                        </span>Leadflow List
                    </Link>
                </div>
                <If condition={leadFlow.source_type == CoreConstants.SOURCE_TYPE.API}>
                    <div className="create_new_leadflow_btn non_active_btn lead_flow_setting_btn accent--bg--color">
                        <a href="#" onClick={(e)=>{e.preventDefault();setOpenApiModal(true)}}>
                            <span className="btn_icon">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.5606 3.74251L12.2575 1.43938C12.1185 1.29965 11.9533 1.18886 11.7712 1.11344C11.5891 1.03802 11.3939 0.999467 11.1969 1.00001H3C2.46957 1.00001 1.96086 1.21072 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3.00001V13C1 13.5304 1.21071 14.0391 1.58579 14.4142C1.96086 14.7893 2.46957 15 3 15H13C13.5304 15 14.0391 14.7893 14.4142 14.4142C14.7893 14.0391 15 13.5304 15 13V4.80313C15.0005 4.60607 14.962 4.41086 14.8866 4.2288C14.8111 4.04674 14.7004 3.88146 14.5606 3.74251ZM3.75 3.50001H9.25C9.3163 3.50001 9.37989 3.52634 9.42678 3.57323C9.47366 3.62011 9.5 3.6837 9.5 3.75001V5.25001C9.5 5.31631 9.47366 5.3799 9.42678 5.42678C9.37989 5.47367 9.3163 5.50001 9.25 5.50001H3.75C3.6837 5.50001 3.62011 5.47367 3.57322 5.42678C3.52634 5.3799 3.5 5.31631 3.5 5.25001V3.75001C3.5 3.6837 3.52634 3.62011 3.57322 3.57323C3.62011 3.52634 3.6837 3.50001 3.75 3.50001ZM8.11719 13.4972C7.6133 13.5209 7.11403 13.3915 6.685 13.1262C6.25597 12.8609 5.91724 12.472 5.71332 12.0106C5.50939 11.5492 5.44982 11.0369 5.54242 10.541C5.63503 10.0451 5.87547 9.58887 6.23217 9.23218C6.58887 8.87548 7.04513 8.63503 7.54101 8.54243C8.03688 8.44983 8.54917 8.5094 9.01056 8.71332C9.47195 8.91724 9.86086 9.25597 10.1262 9.68501C10.3915 10.114 10.5208 10.6133 10.4972 11.1172C10.4676 11.7389 10.2073 12.3272 9.76724 12.7672C9.32716 13.2073 8.73885 13.4676 8.11719 13.4972Z"
                                        fill="white"/>
                                    <path
                                        d="M8 12.5C8.82843 12.5 9.5 11.8284 9.5 11C9.5 10.1716 8.82843 9.5 8 9.5C7.17157 9.5 6.5 10.1716 6.5 11C6.5 11.8284 7.17157 12.5 8 12.5Z"
                                        fill="white"/>
                                </svg>
                            </span>API Link
                        </a>
                    </div>
                </If>
                <If condition={leadFlow.source_type == CoreConstants.SOURCE_TYPE.CALENDAR}>
                    <div className="create_new_leadflow_btn non_active_btn lead_flow_setting_btn accent--bg--color">
                        <a href="#" onClick={(e)=>{e.preventDefault();setOpenCalendarModal(true)}}>
                            <span className="btn_icon">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.5606 3.74251L12.2575 1.43938C12.1185 1.29965 11.9533 1.18886 11.7712 1.11344C11.5891 1.03802 11.3939 0.999467 11.1969 1.00001H3C2.46957 1.00001 1.96086 1.21072 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3.00001V13C1 13.5304 1.21071 14.0391 1.58579 14.4142C1.96086 14.7893 2.46957 15 3 15H13C13.5304 15 14.0391 14.7893 14.4142 14.4142C14.7893 14.0391 15 13.5304 15 13V4.80313C15.0005 4.60607 14.962 4.41086 14.8866 4.2288C14.8111 4.04674 14.7004 3.88146 14.5606 3.74251ZM3.75 3.50001H9.25C9.3163 3.50001 9.37989 3.52634 9.42678 3.57323C9.47366 3.62011 9.5 3.6837 9.5 3.75001V5.25001C9.5 5.31631 9.47366 5.3799 9.42678 5.42678C9.37989 5.47367 9.3163 5.50001 9.25 5.50001H3.75C3.6837 5.50001 3.62011 5.47367 3.57322 5.42678C3.52634 5.3799 3.5 5.31631 3.5 5.25001V3.75001C3.5 3.6837 3.52634 3.62011 3.57322 3.57323C3.62011 3.52634 3.6837 3.50001 3.75 3.50001ZM8.11719 13.4972C7.6133 13.5209 7.11403 13.3915 6.685 13.1262C6.25597 12.8609 5.91724 12.472 5.71332 12.0106C5.50939 11.5492 5.44982 11.0369 5.54242 10.541C5.63503 10.0451 5.87547 9.58887 6.23217 9.23218C6.58887 8.87548 7.04513 8.63503 7.54101 8.54243C8.03688 8.44983 8.54917 8.5094 9.01056 8.71332C9.47195 8.91724 9.86086 9.25597 10.1262 9.68501C10.3915 10.114 10.5208 10.6133 10.4972 11.1172C10.4676 11.7389 10.2073 12.3272 9.76724 12.7672C9.32716 13.2073 8.73885 13.4676 8.11719 13.4972Z"
                                        fill="white"/>
                                    <path
                                        d="M8 12.5C8.82843 12.5 9.5 11.8284 9.5 11C9.5 10.1716 8.82843 9.5 8 9.5C7.17157 9.5 6.5 10.1716 6.5 11C6.5 11.8284 7.17157 12.5 8 12.5Z"
                                        fill="white"/>
                                </svg>
                            </span>
                            <If condition={leadFlow.calendar.length === 0}>
                                Connect Calendar
                            </If>
                            <If condition={leadFlow.calendar.length > 0}>
                                Change Calendar
                            </If>
                        </a>
                    </div>
                </If>
                <div className="create_new_leadflow_btn non_active_btnv lead_flow_setting_btn accent--bg--color">
                    <a href="#" onClick={(e) => {e.preventDefault();props.saveHandler()}}>
                        <span className="btn_icon">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.5606 3.74251L12.2575 1.43938C12.1185 1.29965 11.9533 1.18886 11.7712 1.11344C11.5891 1.03802 11.3939 0.999467 11.1969 1.00001H3C2.46957 1.00001 1.96086 1.21072 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3.00001V13C1 13.5304 1.21071 14.0391 1.58579 14.4142C1.96086 14.7893 2.46957 15 3 15H13C13.5304 15 14.0391 14.7893 14.4142 14.4142C14.7893 14.0391 15 13.5304 15 13V4.80313C15.0005 4.60607 14.962 4.41086 14.8866 4.2288C14.8111 4.04674 14.7004 3.88146 14.5606 3.74251ZM3.75 3.50001H9.25C9.3163 3.50001 9.37989 3.52634 9.42678 3.57323C9.47366 3.62011 9.5 3.6837 9.5 3.75001V5.25001C9.5 5.31631 9.47366 5.3799 9.42678 5.42678C9.37989 5.47367 9.3163 5.50001 9.25 5.50001H3.75C3.6837 5.50001 3.62011 5.47367 3.57322 5.42678C3.52634 5.3799 3.5 5.31631 3.5 5.25001V3.75001C3.5 3.6837 3.52634 3.62011 3.57322 3.57323C3.62011 3.52634 3.6837 3.50001 3.75 3.50001ZM8.11719 13.4972C7.6133 13.5209 7.11403 13.3915 6.685 13.1262C6.25597 12.8609 5.91724 12.472 5.71332 12.0106C5.50939 11.5492 5.44982 11.0369 5.54242 10.541C5.63503 10.0451 5.87547 9.58887 6.23217 9.23218C6.58887 8.87548 7.04513 8.63503 7.54101 8.54243C8.03688 8.44983 8.54917 8.5094 9.01056 8.71332C9.47195 8.91724 9.86086 9.25597 10.1262 9.68501C10.3915 10.114 10.5208 10.6133 10.4972 11.1172C10.4676 11.7389 10.2073 12.3272 9.76724 12.7672C9.32716 13.2073 8.73885 13.4676 8.11719 13.4972Z"
                                    fill="white"/>
                                <path
                                    d="M8 12.5C8.82843 12.5 9.5 11.8284 9.5 11C9.5 10.1716 8.82843 9.5 8 9.5C7.17157 9.5 6.5 10.1716 6.5 11C6.5 11.8284 7.17157 12.5 8 12.5Z"
                                    fill="white"/>
                            </svg>
                        </span>Save Leadflow
                    </a>
                </div>
                <If condition={openCalendarModal}>
                    <AssignCalendarModal
                        openCalendarModal={openCalendarModal}
                        setOpenCalendarModal={setOpenCalendarModal}
                        leadFlow={leadFlow}
                    />
                </If>
                <If condition={openApiModal}>
                    <ApiDetailsModal
                        openApiModal={openApiModal}
                        setOpenApiModal={setOpenApiModal}
                        leadFlow={leadFlow}
                    />
                </If>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        leadFlow: state.leadFlowSettingReducer.leadFlow,
        loading: state.leadFlowSettingReducer.loading,
        calendars: state.leadFlowSettingReducer.calendars,

    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const SettingsHeader = connect(mapStateToProps, mapDispatchToProps)(App)

export default SettingsHeader;