const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/leadFlow`
const profileUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/profile`

const Api = {
    fetchUser: `${profileUrl}/get-user-data`,
    leadFlow: `${rootUrl}/list`,
    createLeadFlow: `${rootUrl}/create`,
    updateLeadFlow: `${rootUrl}/update`,
    deleteLeadFlow: `${rootUrl}/delete`,
    fetchLeadFlowSetting: `${rootUrl}/settings`,
    saveLeadFlow: `${rootUrl}/settings/save`,
    fetchCalendars: `${rootUrl}/calendar/unassigned-list`,
    assignCalendar: `${rootUrl}/calendar/assign`,
    fetchContactSources: `${rootUrl}/contact-sources`,
    addContactSource: `${rootUrl}/contact-sources/add`,
}

export default Api;