import {connect} from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import If, {Else} from 'if-else-react';
import { removeCondition, updateCondition } from '../../actions/leadflowAction';

const App = props => {

    const conditionRemove = () => {
        props.removeCondition({ruleId: props.condition.leadflow_rule_id, order: props.order});
    }

    const renderFields = () => {
        return Object.entries(props.fields).map((data, index)=>{
            return (<MenuItem value={ data[0] }>{data[1]}</MenuItem>);
        });
    }

    const updateFieldHandler = (e) => {
        props.updateCondition({condition: props.condition, typeValue: parseInt(e.target.value), type: 'field'});
    }

    const renderOperators = () => {
        let fieldId = typeof props.condition.field == 'undefined' ? 1 : props.condition.field;
        return Object.entries(props.operators[fieldId]).map((data, index)=>{
            return (<MenuItem value={ data[0] }>{data[1]}</MenuItem>);
        });
    }

    const updateOperatorHandler = (e) => {
        props.updateCondition({condition: props.condition, typeValue: parseInt(e.target.value), type: 'operator'});
    }

    const renderTags = () => {
        let tags = [];
        if (props.tags.length) {
            tags.push(<MenuItem value="select_tag" disabled>Select a tag</MenuItem>);
        } else {
            tags.push(<MenuItem value="select_tag" disabled>No tag found</MenuItem>);
        }

        props.tags.map((data, index)=>{
            tags.push(<MenuItem key={index} value={ data.id }>{data.name}</MenuItem>);
        });

        return tags;
    }

    const updateValueHandler = (e) => {
        let value = parseInt(e.target.value);
        if (props.condition.field === 1) {
            let tag = props.tags.filter(tag => tag.id === value);
            value = {value: value, label: tag[0].name}
        }
        props.updateCondition({condition: props.condition, typeValue: value, type: 'value'});
    }

    return (
        <div className="rule_input_content d-flex justify-content-between align-items-center">
            <div className="rule_inputs d-flex">
                <div className="rule_select pp_select">
                    <Select
                        labelId="personalize-select-label"
                        value={props.condition.field}
                        onChange={updateFieldHandler}
                    >
                        { renderFields() }
                    </Select>
                </div>
                <div className="rule_input pp_select">
                    <Select
                        labelId="personalize-select-label"
                        value={props.condition.operator}
                        onChange={updateOperatorHandler}
                    >
                        { renderOperators() }
                    </Select>
                </div>
                <If condition={(props.condition.field !== 3 && props.condition.operator !== 7 && props.condition.operator !== 8)}>
                    <div className="rule_input rule_input_search">
                        <If condition={props.condition.field === 1}>
                            <Select
                                labelId="personalize-select-label"
                                value={(typeof props.condition.value === 'undefined' || props.condition.value === '') ? "select_tag" : (typeof props.condition.value[0] === 'undefined' ? props.condition.value.value :  props.condition.value[0].value)}
                                onChange={updateValueHandler}
                            >
                                { renderTags() }
                            </Select>
                        <Else />
                            <input placeholder="Enter the price" id="first_name" type="number" onChange={(e) => updateValueHandler(e)} value={props.condition.value}/>
                        </If>
                    </div>
                </If>
            </div>
            <div className="delete_btn">
                <button onClick={() => conditionRemove()}>
                    <span className="delete_btn_icon">
                        <svg width="13" height="16" viewBox="0 0 13 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.7497 1.99983H8.99979V1.49984C8.99979 0.671425 8.32824 -0.00012207 7.49983 -0.00012207H5.49987C4.67145 -0.00012207 3.99991 0.671425 3.99991 1.49984V1.99983H1.24997C0.559643 1.99983 0 2.55947 0 3.2498V4.24978C0 4.52593 0.223839 4.74977 0.499988 4.74977H12.4997C12.7759 4.74977 12.9997 4.52593 12.9997 4.24978V3.2498C12.9997 2.55947 12.4401 1.99983 11.7497 1.99983ZM4.99988 1.49984C4.99988 1.22422 5.22425 0.999855 5.49987 0.999855H7.49983C7.77544 0.999855 7.99981 1.22422 7.99981 1.49984V1.99983H4.99988V1.49984Z"
                                fill="#FF264A"/>
                            <path
                                d="M0.948964 5.75037C0.859748 5.75037 0.788656 5.82493 0.792906 5.91405L1.2054 14.5714C1.24352 15.3726 1.90163 16.0001 2.70349 16.0001H10.2964C11.0983 16.0001 11.7564 15.3726 11.7945 14.5714L12.207 5.91405C12.2113 5.82493 12.1402 5.75037 12.051 5.75037H0.948964ZM8.49991 7.00034C8.49991 6.72409 8.72366 6.50035 8.9999 6.50035C9.27615 6.50035 9.49989 6.72409 9.49989 7.00034V13.5002C9.49989 13.7764 9.27615 14.0002 8.9999 14.0002C8.72366 14.0002 8.49991 13.7764 8.49991 13.5002V7.00034ZM5.99997 7.00034C5.99997 6.72409 6.22372 6.50035 6.49996 6.50035C6.7762 6.50035 6.99995 6.72409 6.99995 7.00034V13.5002C6.99995 13.7764 6.7762 14.0002 6.49996 14.0002C6.22372 14.0002 5.99997 13.7764 5.99997 13.5002V7.00034ZM3.50003 7.00034C3.50003 6.72409 3.72378 6.50035 4.00002 6.50035C4.27626 6.50035 4.50001 6.72409 4.50001 7.00034V13.5002C4.50001 13.7764 4.27626 14.0002 4.00002 14.0002C3.72378 14.0002 3.50003 13.7764 3.50003 13.5002V7.00034Z"
                                fill="#FF264A"/>
                        </svg>
                    </span>Delete
                </button>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        fields: state.leadFlowSettingReducer.fields,
        operators: state.leadFlowSettingReducer.operators,
        tags : state.leadFlowSettingReducer.tags,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        removeCondition: (params) => dispatch(removeCondition(params)),
        updateCondition: (params) => dispatch(updateCondition(params)),
    }
}

const EachCondition = connect(mapStateToProps, mapDispatchToProps)(App)

export default EachCondition;