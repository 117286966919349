import { all } from "redux-saga/effects";
import leadFlowMiddleware from "./leadflowMiddleware";

export default function* rootMiddleware() {
    yield all(
        [
            leadFlowMiddleware(),
        ]
    )
}
