import {useEffect, useState} from "react";
import {connect} from "react-redux";
import Assignee from "./Assignee";

const DefaultRule = props => {

    return (
        <div className="add_rule_content">
            <div className="add_rule_top_content d-flex justify-content-between">
                <div className="list__title rule_btm_title">
                    Default Rule (if none of the above apply)
                </div>
                <div className="top_lead_setting_btn d-flex">
                    <div className="create_new_leadflow_btn add_rule_btn">
                        <a href="#" className="modal-trigger" onClick={(e) => props.addAnotherRule(e)}>
                            <span className="btn_icon">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.99992 9.00004H2.33325H8.99992Z" fill="#3C7EF3"/>
                                    <path
                                        d="M8.99992 15.6667V9.00004M8.99992 9.00004V2.33337M8.99992 9.00004H15.6666M8.99992 9.00004H2.33325"
                                        stroke="#3C7EF3" strokeWidth="4" strokeLinecap="round"/>
                                </svg>
                            </span>Add Another Rule
                        </a>
                    </div>
                </div>
            </div>
            <Assignee isDefault={true} rule={props.rule}/>
        </div>
    );
}

export default DefaultRule;