import React, {createContext} from "react";
import Routes from "./routes/Routes";
import { createBrowserHistory } from "history";
import {BrowserRouter as Router} from 'react-router-dom';

const defaultHistory = createBrowserHistory();
export const HistoryProvider = createContext({});

function App({ history = defaultHistory }) {
    return (
        <HistoryProvider.Provider value={history}>
            <Router history={history}>
                <React.Fragment>
                    <Routes />
                </React.Fragment>
            </Router>
        </HistoryProvider.Provider>
    );
}

export default App;
