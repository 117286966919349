export const FETCH_USER = 'FETCH_USER';
export const STORE_USER = 'STORE_USER';
export const FETCH_LEADFLOW = 'FETCH_LEADFLOW';
export const STORE_LEADFLOW = 'STORE_LEADFLOW';
export const FETCH_LEADFLOW_SETTINGS = 'FETCH_LEADFLOW_SETTINGS';
export const STORE_LEADFLOW_SETTINGS = 'STORE_LEADFLOW_SETTINGS';
export const UPDATE_ASSIGNEE = 'UPDATE_ASSIGNEE';
export const UPDATE_ASSIGNEE_CAMPAIGN = 'UPDATE_ASSIGNEE_CAMPAIGN';
export const UPDATE_RULE_CONDITION_TYPE = 'UPDATE_RULE_CONDITION_TYPE';
export const DELETE_RULE = 'DELETE_RULE';
export const UPDATE_RULE_CONDITION = 'UPDATE_RULE_CONDITION';
export const REMOVE_RULE_CONDITION = 'REMOVE_RULE_CONDITION';
export const ADD_RULE_CONDITION = 'ADD_RULE_CONDITION';
export const ADD_NEW_RULE = 'ADD_NEW_RULE';
export const ERROR_RULE = 'ERROR_RULE';
export const ERROR_RULE_CONDITION = 'ERROR_RULE_CONDITION';
export const ERROR_CLEAR = 'ERROR_CLEAR';
export const UPDATE_RULE_ORDER = 'UPDATE_RULE_ORDER';