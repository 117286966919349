import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import {IconList} from "../../constants/IconList";
import { makeStyles } from '@material-ui/core/styles';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    accordionHeader: {
        fontSize: '18px',
        fontWeight: 'bolder',
        color: '#133159'
    },
    accordionSection: {
        marginBottom: '15px',
    }
}));

const App = props => {
    const classes = useStyles();
    const [campaignId, setCampaignId] = useState('');
    const [campaignType, setCampaignType] = useState('');
    const [accordionExpanded, setAccordionExpanded] = useState('');

    const handleChange = (id, type) => {
        setCampaignType(type);
        setCampaignId(id);
    };

    const isCampaign = data => {
        if(!campaignType){
            return (parseInt(campaignId) === parseInt(data.id));
        } else {
            if((parseInt(campaignId) === parseInt(data.id) && parseInt(campaignType) === 1)){
                return true;
            }
        }

        return false;
    }

    const renderCampaigns = () => {
        let campaigns = [];
        if(props.assign_type == 1){
            if(props.assign_to != undefined && props.assign_to != null && props.assign_to !== ""){
                props.campaigns.map((data, index) => {
                    if(data.user_id == props.assign_to){
                        campaigns.push(
                            <label>
                                <input type="radio" className="filled-in" defaultChecked={isCampaign(data)} onChange={()=>{handleChange(data.id,1)}} name="campaign"/>
                                <span className="checkbox__text_1">{data.title}{data.contact_needed == 1 ? '  ('+'Lead Needed'+')' : ''}</span>
                            </label>
                        );
                    }
                });
            }
            else{
                props.campaigns.map((data, index) => {
                    campaigns.push(
                        <label>
                            <input type="radio" className="filled-in" defaultChecked={isCampaign(data)} onChange={()=>{handleChange(data.id,1)}} name="campaign"/>
                            <span className="checkbox__text_1">{data.title}{data.contact_needed == 1 ? '  ('+'Lead Needed'+')' : ''}</span>
                        </label>
                    );
                });
            }
        }
        else{
            props.campaigns.map((data, index) => {
                campaigns.push(
                    <label>
                        <input type="radio" className="filled-in" defaultChecked={isCampaign(data)} onChange={()=>{handleChange(data.id,1)}} name="campaign"/>
                        <span className="checkbox__text_1">{data.title}{data.contact_needed == 1 ? '  ('+'Lead Needed'+')' : ''}</span>
                    </label>
                );
            });
        }
        return campaigns;
    }

    const renderTemplates = () => {
        let templates = [];
        if (props.templates.length) {
            props.templates.map((data, index) => {
                templates.push(
                    <label>
                        <input type="radio" className="filled-in" defaultChecked={(parseInt(campaignId) === parseInt(data.id) && parseInt(campaignType) === 2)} onChange={()=>{handleChange(data.id,2)}} name="template"/>
                        <span className="checkbox__text_1">{ data.title }</span>
                    </label>
                );
            });
        } else {
            templates = 'No template found!'
        }

        return templates;
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        props.callback(campaignId, campaignType);
        props.setOpenCampaignModal(false);
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setAccordionExpanded(isExpanded ? panel : false);
    };

    return (
        <React.Fragment>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.openCampaignModal}
                onClose={props.setOpenCampaignModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.openCampaignModal}>
                    <div className="add_leadflow_modal" id="add_new_leadFlow_modal">
                        <div className="top_content d-flex justify-content-between align-items-center p-4">
                            <div className="sub_title d-flex align-items-center">
                                <h2>Assign campaign</h2>
                            </div>
                            <div className="cross_button d-flex justify-content-center align-items-center modal-close" onClick={(e) => {e.preventDefault();e.stopPropagation();props.setOpenCampaignModal(false)}}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.1252 17.0812L17.1357 15.0693L6.878 4.80485L4.86747 6.8167L15.1252 17.0812Z" fill="white"/>
                                    <path d="M6.878 17.0838L17.1357 6.81934L15.1252 4.80749L4.86747 15.072L6.878 17.0838Z" fill="white"/>
                                </svg>
                            </div>
                        </div>
                        <div className="manage_modal_input_fields d-flex justify-content-start align-items-center">
                            <div className="modal__input__box modal__search__box">
                                <div className="modal__search__preview awesome__scroll_bar">
                                    <Accordion className={classes.accordionSection} expanded={accordionExpanded === 'campaign'} onChange={handleAccordionChange('campaign')}>
                                        <AccordionSummary
                                            expandIcon={accordionExpanded === 'campaign' ? IconList.minusIcon : IconList.plusIcon}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className={classes.accordionHeader}>Campaign</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="w-100">
                                                { renderCampaigns() }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion expanded={accordionExpanded === 'template'} onChange={handleAccordionChange('template')}>
                                        <AccordionSummary
                                            expandIcon={accordionExpanded === 'template' ? IconList.minusIcon : IconList.plusIcon}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className={classes.accordionHeader}>Campaign template</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div>
                                                { renderTemplates() }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>

                                </div>
                            </div>
                        </div>
                        <div className="modal__buttons d-flex justify-content-center align-content-center">
                                <button type="button" className="add__group__btn accent--bg--color" onClick={(e) => handleSubmit(e)}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.5606 3.74251L12.2575 1.43938C12.1185 1.29965 11.9533 1.18886 11.7712 1.11344C11.5891 1.03802 11.3939 0.999467 11.1969 1.00001H3C2.46957 1.00001 1.96086 1.21072 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3.00001V13C1 13.5304 1.21071 14.0391 1.58579 14.4142C1.96086 14.7893 2.46957 15 3 15H13C13.5304 15 14.0391 14.7893 14.4142 14.4142C14.7893 14.0391 15 13.5304 15 13V4.80313C15.0005 4.60607 14.962 4.41086 14.8866 4.2288C14.8111 4.04674 14.7004 3.88146 14.5606 3.74251ZM3.75 3.50001H9.25C9.3163 3.50001 9.37989 3.52634 9.42678 3.57323C9.47366 3.62011 9.5 3.6837 9.5 3.75001V5.25001C9.5 5.31631 9.47366 5.3799 9.42678 5.42678C9.37989 5.47367 9.3163 5.50001 9.25 5.50001H3.75C3.6837 5.50001 3.62011 5.47367 3.57322 5.42678C3.52634 5.3799 3.5 5.31631 3.5 5.25001V3.75001C3.5 3.6837 3.52634 3.62011 3.57322 3.57323C3.62011 3.52634 3.6837 3.50001 3.75 3.50001ZM8.11719 13.4972C7.6133 13.5209 7.11403 13.3915 6.685 13.1262C6.25597 12.8609 5.91724 12.472 5.71332 12.0106C5.50939 11.5492 5.44982 11.0369 5.54242 10.541C5.63503 10.0451 5.87547 9.58887 6.23217 9.23218C6.58887 8.87548 7.04513 8.63503 7.54101 8.54243C8.03688 8.44983 8.54917 8.5094 9.01056 8.71332C9.47195 8.91724 9.86086 9.25597 10.1262 9.68501C10.3915 10.114 10.5208 10.6133 10.4972 11.1172C10.4676 11.7389 10.2073 12.3272 9.76724 12.7672C9.32716 13.2073 8.73885 13.4676 8.11719 13.4972Z"
                                            fill="white"/>
                                        <path
                                            d="M8 12.5C8.82843 12.5 9.5 11.8284 9.5 11C9.5 10.1716 8.82843 9.5 8 9.5C7.17157 9.5 6.5 10.1716 6.5 11C6.5 11.8284 7.17157 12.5 8 12.5Z"
                                            fill="white"/>
                                    </svg>
                                    <span className="mx-2">Save</span>
                                </button>
                                <button onClick={() => props.setOpenCampaignModal(false)} className="modal-close modal__close__btn">
                                    <svg width="21" height="22" viewBox="0 0 21 22" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.1016 3.90156C14.2016 0.0015626 7.80156 0.0015626 3.90156 3.90156C0.00156271 7.80156 0.00156271 14.2016 3.90156 18.1016C7.80156 22.0016 14.1016 22.0016 18.0016 18.1016C21.9016 14.2016 22.0016 7.80156 18.1016 3.90156ZM13.8016 15.2016L11.0016 12.4016L8.20156 15.2016L6.80156 13.8016L9.60156 11.0016L6.80156 8.20156L8.20156 6.80156L11.0016 9.60156L13.8016 6.80156L15.2016 8.20156L12.4016 11.0016L15.2016 13.8016L13.8016 15.2016Z"
                                            fill="white"/>
                                    </svg>
                                    <span className="mx-2">Close</span>
                                </button>
                            </div>
                    </div>
                </Fade>
            </Modal>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        campaigns   : state.leadFlowSettingReducer.campaigns,
        templates   : state.leadFlowSettingReducer.templates,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const CampaignAssigneeModal = connect(mapStateToProps, mapDispatchToProps)(App)

export default CampaignAssigneeModal;