import Fade from '@material-ui/core/Fade';
import React, {useEffect, useRef, useState} from "react";
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import {fetchLeadFlow} from "../../actions/leadflowAction";
import {connect} from "react-redux";
import Utils from '../../helpers/Utils';

const ApiDetailsModal = props => {
    const apiDetails = {
        rootUrl: Utils.getAccountData('baseUrl'),
        link: '/api/v1/lead-flow',
        method: 'POST',
        params: [
            {
                name: 'token',
                type: 'string',
                desc: props.leadFlow.token,
                required: true
            },
            {
                name: 'number',
                type: 'number',
                desc: "Lead's Number (mandatory if email is not present)",
                required: false
            },
            {
                name: 'email',
                type: 'email',
                desc: "Lead's Email (mandatory if number is not present)",
                required: false
            },
            {
                name: 'first_name',
                type: 'string',
                desc: "Lead's first name",
                required: false
            },
            {
                name: 'last_name',
                type: 'string',
                desc: "Lead's last name",
                required: false
            },
            {
                name: 'address',
                type: 'string',
                desc: "Lead's address",
                required: false
            },
            {
                name: 'tag',
                type: 'coma separated string',
                desc: "tag1, tag2, tag3",
                required: false
            },
            {
                name: 'price',
                type: 'number',
                desc: "Ex: 5000",
                required: false
            },
            {
                name: 'country',
                type: 'string',
                desc: "Lead's country",
                required: false
            },
            {
                name: 'zip',
                type: 'number',
                desc: "Lead's zip",
                required: false
            },
            {
                name: 'city',
                type: 'string',
                desc: "Lead's city",
                required: false
            },
            {
                name: 'state',
                type: 'string',
                desc: "Lead's state",
                required: false
            },
            {
                name: 'customfields',
                type: 'mix',
                desc: "Add custom fields. Name the field same as the system's value without brackets. Ex: custom_field_1, custom_field_2. ",
                required: false
            },
        ],
        headers: [
        ]
    };

    const copyUrl = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(apiDetails.rootUrl + apiDetails.link);
        Utils.showNotification('Link copied to clipboard', 'success');
    }

    return (
        <React.Fragment>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                // className={classes.modal}
                open={props.openApiModal}
                onClose={props.setOpenApiModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.openApiModal}>
                    <div className="add_leadflow_modal api_link" id="api-link">
                        <div className="api_link_top_content d-flex justify-content-between align-items-center p-4">
                            <div className="sub_title">
                                <h2>Api Link</h2>
                            </div>
                            <a className="cross_button d-flex justify-content-center align-items-center modal-close" onClick={(e) => {e.preventDefault();props.setOpenApiModal(false)}}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M15.1252 17.0812L17.1357 15.0693L6.878 4.80485L4.86747 6.8167L15.1252 17.0812Z"
                                        fill="white"/>
                                    <path
                                        d="M6.878 17.0838L17.1357 6.81934L15.1252 4.80749L4.86747 15.072L6.878 17.0838Z"
                                        fill="white"/>
                                </svg>
                            </a>
                        </div>
                        <div className="http_form_group d-flex justify-content-between">
                            <div className="form-group">
                                <label htmlFor="http_post">HTTP Methods: <strong className="text-danger">POST</strong></label>
                                <input id="http_post" type="text" className="validate mt-2" value={apiDetails.rootUrl + apiDetails.link} readOnly/>
                            </div>
                            <a href="#" title={'Copy'} onClick={(e) => copyUrl(e)}>
                                <span>
                                    <svg width="13" height="14" viewBox="0 0 13 14" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.18751 0.0135711C9.13326 0.0135711 3.9375 0 3.9375 0C3.01174 0 2.18751 0.849194 2.18751 1.75001L1.68175 1.76182C0.756438 1.76182 0 2.59918 0 3.5V12.25C0 13.1508 0.824254 14 1.75001 14H8.31251C9.23825 14 10.0625 13.1508 10.0625 12.25H10.5C11.4258 12.25 12.25 11.4008 12.25 10.5V3.51051L9.18751 0.0135711ZM8.31251 13.125H1.75001C1.29063 13.125 0.875015 12.6959 0.875015 12.25V3.5C0.875015 2.84901 1.41051 2.62501 2.18751 2.62501V10.5C2.18751 11.4008 3.01174 12.25 3.9375 12.25C3.9375 12.25 8.68087 12.2452 9.19143 12.2452C9.19143 12.7763 8.84321 13.125 8.31251 13.125ZM9.18751 9.19231H5.25001C5.00851 9.19231 4.81251 8.99675 4.81251 8.75523C4.81251 8.51376 5.00851 8.3182 5.25001 8.3182H9.18751C9.42901 8.3182 9.62501 8.51376 9.62501 8.75523C9.62501 8.99677 9.42901 9.19231 9.18751 9.19231ZM9.18751 7.00701H5.25001C5.00851 7.00701 4.81251 6.81143 4.81251 6.56995C4.81251 6.32845 5.00851 6.13289 5.25001 6.13289H9.18751C9.42901 6.13289 9.62501 6.32845 9.62501 6.56995C9.62501 6.81101 9.42901 7.00701 9.18751 7.00701ZM10.0625 3.5C9.59658 3.5 9.18751 3.07958 9.18751 2.62501C9.18751 2.62501 9.18751 1.73862 9.18751 0.888566V0.887685L11.375 3.5H10.0625Z"
                                            fill="#133159" fillOpacity="0.65"/>
                                    </svg>
                                </span>
                            </a>
                        </div>
                        <div className="query_parameters">
                        <h5 className="pb-1">HEADERS</h5>
                        <hr/>
                        {!apiDetails.headers.length ?
                            <label htmlFor="authorized">None</label>
                            :
                            apiDetails.headers.map((header, index) => (
                                <div className="number_text" key={index}>
                                    <h4>{header.name} {header.required ? <p>*</p> : ''} <span>{header.type}</span></h4>
                                    <p>{header.desc}</p>
                                </div>
                            ))
                        }
                        </div>
                        <div className="query_parameters">
                            <h5>Query Parameters</h5>
                            <hr/>
                            {apiDetails.params.map((param, index) => (
                                <div className="number_text" key={index} >
                                    <h4>{param.name} {param.required ? <p>*</p> : ''} <span>{param.type}</span></h4>
                                    <p>{param.desc}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </Fade>
            </Modal>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    leadFlows: state.leadFlowReducer.leadFlows
});

const mapDispatchToProps = dispatch => ({
    fetchLeadFlow : () => dispatch(fetchLeadFlow()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiDetailsModal);
