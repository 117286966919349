import * as ACTION from "../constants/leadflowActionTypes";
import React from "react";
import {UPDATE_RULE_CONDITION_TYPE} from "../constants/leadflowActionTypes";

const initialState = {
    loading         : true,
    leadFlow        : {},
    leadFlowRules   : [],
    fields          : [],
    operators       : [],
    members         : [],
    poolId          : null,
    templates       : [],
    campaigns       : [],
    groups          : [],
    calendars       : [],
    currentUser     : {},
    errorFound : false,
    errors : [],
    removeRules : [],
    tags : [],
    response: false,
    userInfo: {}
};

const marketingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.STORE_LEADFLOW_SETTINGS:
            return {...state,
                leadFlow : action.payload.html,
                leadFlowRules : action.payload.html.rules,
                fields : action.payload.fields,
                operators : action.payload.operators,
                members : action.payload.members,
                groups : action.payload.groups,
                pool : action.payload.pool,
                currentUser : action.payload.currentUser,
                templates : action.payload.templates,
                campaigns : action.payload.campaigns,
                calendars : action.payload.calendars,
                tags : action.payload.tags,
                loading: false
            }

        case ACTION.STORE_USER:
            return {...state, userInfo: action.payload}

        case ACTION.UPDATE_ASSIGNEE:
            let assigneeUpdateRule = state.leadFlowRules.map(rule => rule.id === action.payload.ruleId ? {...rule, assign_type: parseInt(action.payload.assignType), assign_to: parseInt(action.payload.assignTo), campaign_id: null} : rule);
            return {...state, leadFlowRules : assigneeUpdateRule}

        case ACTION.UPDATE_ASSIGNEE_CAMPAIGN:
            let campaignUpdateRule = state.leadFlowRules.map(rule => rule.id === action.payload.ruleId ? {...rule, campaign_id: parseInt(action.payload.campaignId), campaign_type: parseInt(action.payload.campaignType)} : rule);
            return {...state, leadFlowRules : campaignUpdateRule}

        case ACTION.UPDATE_RULE_CONDITION_TYPE:
            let conditionTypeUpdateRule = state.leadFlowRules.map(rule => rule.id === action.payload.ruleId ? {...rule, condition_type: parseInt(action.payload.conditionType)} : rule);
            return {...state, leadFlowRules : conditionTypeUpdateRule}

        case ACTION.DELETE_RULE:
            let newLeadFlowRules = state.leadFlowRules.filter((rule,index) => {
                return !(rule.id === action.payload.ruleId);
            });
            return {...state, leadFlowRules : newLeadFlowRules, removeRules: state.removeRules.concat(action.payload.ruleId)}

        case ACTION.REMOVE_RULE_CONDITION:
            let updateRule = state.leadFlowRules.map(rule => rule.id === action.payload.ruleId ? {...rule, conditions: rule.conditions.filter((value, index) => {return index !==  action.payload.order;})} : rule);
            return {...state, leadFlowRules : updateRule}

        case ACTION.UPDATE_RULE_CONDITION:
            let updateConditionRule = state.leadFlowRules.map(rule => rule.id === action.payload.condition.leadflow_rule_id ? {...rule, conditions: rule.conditions.map(condition => condition.id === action.payload.condition.id ? {...condition, [action.payload.type]: action.payload.typeValue} : condition)} : rule);
            return {...state, leadFlowRules : updateConditionRule}

        case ACTION.ADD_RULE_CONDITION:
            let addNewConditionRule = state.leadFlowRules.map(rule => rule.id === action.payload.rule.id ? {...rule, conditions: rule.conditions.concat(action.payload.newCondition)} : rule);
            return {...state, leadFlowRules : addNewConditionRule}

        case ACTION.ADD_NEW_RULE:
            return {...state, leadFlowRules : state.leadFlowRules.concat(action.payload.newRule)}

        case ACTION.ERROR_RULE:
        case ACTION.ERROR_RULE_CONDITION:
            return {...state, errors : state.errors.concat(action.payload.message), errorFound: true}

        case ACTION.ERROR_CLEAR:
            return {...state, errors : [], errorFound: false}

        case ACTION.UPDATE_RULE_ORDER:
            return {...state, leadFlowRules : action.payload.leadFlowRules}

        default:
            return state;
    }
}

export default marketingReducer;
