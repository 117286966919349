import axios from "axios";
import { getCookie } from "../helpers/Cookie";

export const sendRequest = (method, url, payload = [], config = []) => {
    let data = [];
    try{
        payload.append('api-key', getCookie(process.env.REACT_APP_ACCESS_TOKEN))
        data = payload;
    } catch (error) {
        data = {...payload, ...{'api-key': getCookie(process.env.REACT_APP_ACCESS_TOKEN)}};
    }
    return axios.request({
        method: method,
        url: url,
        data: data,
        params: (method === 'get' || method === 'GET') ? data : null
    });
}
