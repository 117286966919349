const CoreConstants = {
    SOURCE_TYPE: {
        API: 1,
        ZAPIER: 2,
        FACEBOOK: 3,
        PARSER: 4,
        CALENDAR: 5
    },
    RULE_TYPE: {
        DEFAULT: 1,
        USER_CREATED: 2
    }
}
export default CoreConstants;